import styled from 'styled-components';

const ButtonStyle = styled.div`
	--buttonPaddingL: var(--sp1x) var(--sp12x);
	--buttonPaddingM: var(--sp1x) var(--sp5x);
	--buttonPaddingS: var(--sp1x) var(--sp2x);
	--arrowWidthHeight: var(--sp12x);
	--arrowFontSize: var(--sp7x);

	&.default {
		a,
		button {
			position: relative;
			background-color: var(--lightGreen);
			border-radius: var(--sp2-5x);
			transition: 0.3s;
			padding: var(--buttonPaddingL);
			box-shadow: 0px 5px 0px 0px var(--green);
			overflow: hidden;
			display: inline-block;
			cursor: pointer;

			.avocado-1,
			.avocado-2,
			.avocado-3 {
				position: absolute;
				top: -100%;
				transform: translateY(-50%);
				width: var(--sp3x);
				padding-top: var(--sp3x);
				z-index: 100;

				img {
					object-fit: contain;
				}
			}

			.avocado-1 {
				left: var(--sp2x);
			}

			.avocado-2 {
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.avocado-3 {
				right: var(--sp2x);
			}

			.button-text {
				position: relative;
			}

			@media (hover: hover) {
				&:hover {
					box-shadow: 0px 0px 0px 0px var(--green);
				}
			}
		}

		&.out-of-stock {
			pointer-events: none;

			a,
			button {
				width: fit-content !important;
				position: relative;
				background-color: transparent;
				border-radius: var(--sp2-5x);
				border: 2px solid var(--green);
				transition: unset;
				padding: var(--buttonPaddingL);
				box-shadow: unset;
				overflow: hidden;
				display: inline-block;
				pointer-events: none;
				cursor: default;

				@media (hover: hover) {
					&:hover {
						box-shadow: unset;
					}
				}
			}
		}

		&.medium {
			a,
			button {
				padding: var(--buttonPaddingM);
			}
		}

		&.small {
			a,
			button {
				padding: var(--buttonPaddingS);
				width: var(--sp14x);
			}
		}
	}

	&.arrow {
		background-color: var(--white);
		width: var(--arrowWidthHeight);
		height: var(--arrowWidthHeight);
		cursor: pointer;

		button,
		a {
			width: 100%;
			height: 100%;
			display: block;
			cursor: inherit;

			i {
				font-style: normal;
				font-size: var(--arrowFontSize);
				color: var(--green);
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&.rotate {
			button,
			a {
				i {
					transform: rotate(180deg);
				}
			}
		}
	}

	&.float-underline {
		position: relative;
		width: fit-content;

		@media (hover: hover) {
			&:hover {
				button,
				a {
					&::before {
						top: calc(100% + var(--sp1x));
						opacity: 0;
					}

					.button-text {
						transform: translateY(calc(var(--sp1x) * -1));
					}
				}
			}
		}

		button,
		a {
			cursor: pointer;

			.button-text {
				transform: translateY(0);
				transition: 0.3s ease-out;
			}

			&::before {
				content: '';
				position: absolute;
				top: calc(100% + var(--sp0-5x));
				left: 0;
				background-color: var(--green);
				width: 100%;
				height: 2px;
				opacity: 1;
				transition: 0.3s ease-out;
			}
		}
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--buttonPaddingL: var(--sp1x) var(--sp12x);
		--arrowWidthHeight: var(--sp7x);
		--arrowFontSize: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--buttonPaddingL: var(--sp1x) var(--sp10x);
		--arrowWidthHeight: var(--sp7x);
		--arrowFontSize: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--buttonPaddingL: var(--sp1x) var(--sp6x);
		--arrowWidthHeight: var(--sp7x);
		--arrowFontSize: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--buttonPaddingL: var(--sp1x) var(--sp6x);
		--arrowWidthHeight: var(--sp6x);

		--arrowFontSize: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--buttonPaddingL: var(--sp1x) var(--sp6x);
		--arrowWidthHeight: var(--sp5x);

		--arrowFontSize: var(--sp3x);
	}
`;

export default ButtonStyle;
