import styled from 'styled-components';

const CartListPopupStyle = styled.section`
	--headerPaddingSize: var(--sp4x);
	--headerPaddingLeftRight: var(--sp14x);
	--contentPadding: var(--sp6x) var(--sp15x);
	--widthPopup: 45vw;
	--buttonsDistance: var(--sp6x);

	width: calc(var(--widthPopup) + var(--headerPaddingLeftRight));
	height: calc(100vh - var(--headerFixHeight) + var(--headerPaddingSize));
	position: absolute;
	right: calc(-1 * var(--headerPaddingLeftRight) - 4px);
	background-color: var(--white);
	z-index: 99;

	.cart-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding: var(--contentPadding);

		.underLineBag {
			border-bottom: 1px solid var(--darkGrey);
			padding-bottom: var(--sp1-5x);
		}
	}

	.cart-subtotal {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		gap: 12vw;
		padding: var(--sp1-5x) 0;
		margin-bottom: calc(var(--buttonsDistance) / 2);
	}

	.buttonCheckout {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: var(--buttonsDistance);
	}

	.cart-top {
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	//! 1440
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--headerPaddingSize: var(--sp3x);
		--contentPadding: var(--sp5x) var(--sp10x);
		--headerPaddingLeftRight: var(--sp5x);
		--buttonsDistance: var(--sp5x);
		--widthPopup: 50vw;
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--headerPaddingSize: var(--sp3x);
		--contentPadding: var(--sp4x) var(--sp8x);
		--headerPaddingLeftRight: var(--sp5x);
		--buttonsDistance: var(--sp4x);
		--widthPopup: 50vw;
	}
`;
export default CartListPopupStyle;
