'use client';

import { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { usePathname, useParams, useRouter } from 'next/navigation';
import gsap from 'gsap';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';
import withDataContext from '@/context/consumerHOC/DataConsumer';
import withUserContext from '@/context/consumerHOC/UserConsumer';
import withCartContext from '@/context/consumerHOC/CartConsumer';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Helpers
import { config } from '@/helpers';

//* Components
import BurgerMenu from './BurgerMenu';
import SearchPopup from './SearchPopup';
import CartListPopup from './CartListPopup';
import Text from '@/components/common/Text';
import Icon from '@/components/common/Icon';
import Image from '@/components/common/Image';
import Button from '@/components/common/Button';
import CustomLink from '@/components/common/CustomLink';
import FormItem from '@/components/common/Form/FormItem';
import FormInput from '@/components/common/Form/FormInput';
import FormContainer from '@/components/common/Form/FormContainer';

//* Style
import HeaderStyle from './style';
import CustomImage from '@/components/common/Image';

const AppHeader = ({ winWidth, winHeight, toggleBurgerMenu, closeBurgerMenu, isBurgerMenuOpen, translate, isLoggedIn, login, itemsCount, isTouch, items, globalData }) => {
	//! States
	const [isDropdownActive, setIsDropdownActive] = useState(false);
	const [isSearchDropdownActive, setIsSearchDropdownActive] = useState(false);
	const [isLoginDropdownActive, setIsLoginDropdownActive] = useState(false);
	const [listCartPopup, setListCartPopup] = useState(false);

	//! Ref
	const faceRef = useRef();

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Checks
	const checkSmile = useMemo(() => !pathname.includes(config.routes.login.path), [pathname]);
	const isDesktopSize = useMemo(() => winWidth >= 1280, [winWidth]);

	//! Event Listener
	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove);

		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	//! Close Burger Menu State
	useEffect(() => {
		closeBurgerMenu();
	}, [winWidth, pathname, params]);

	//! Handle Mouse Move
	const handleMouseMove = useCallback(
		(e) => {
			let winPartX = winWidth / 2;
			let winPartY = winHeight / 2;

			let horizontal = ((e.clientX - winPartX) * 4) / winPartX;
			let vertical = ((e.clientY - winPartY) * 4) / winPartY;

			gsap.to(faceRef.current, { x: horizontal, y: vertical });
		},
		[winWidth, winHeight, faceRef]
	);

	//! List items
	let listData = Object.values(config.routes).filter((item) => item.header);

	listData = listData.map((item) => {
		if (item.slug == 'pantry') {
			return {
				...item,
				children: globalData.categories,
			};
		}

		return item;
	});

	const storeListItems = useCallback(
		(placement) => {
			const headerPositionDesktop = listData.slice().sort((a, b) => a.desktop - b.desktop);
			const headerPositionMobile = listData.slice().sort((a, b) => a.mobile - b.mobile);

			const configSort = placement === 'header' ? headerPositionDesktop : headerPositionMobile;
			const isBurgerMenuList = placement === 'burger';

			return configSort.map((listItem, index) => {
				return !!listItem.children ? (
					<li
						key={index}
						className={`list-item ${isDesktopSize ? `with-dropdown` : ''}`}
						onMouseEnter={() => isDesktopSize && setIsDropdownActive(true)}
						onMouseLeave={() => isDesktopSize && setIsDropdownActive(false)}>
						<CustomLink
							url={listItem.path}
							className={'header-menu-item'}>
							<Text
								className={`h4 font-futura-medium ${listItem?.hasColor ? 'colorTextIndividualHeaderBurger' : ''}`}
								text={listItem.name}
							/>

							{!isBurgerMenuList ? (
								<Icon
									className={'menu-icon'}
									name={'arrow'}
								/>
							) : null}
						</CustomLink>

						{isDesktopSize ? (
							<div className={`dropdown`}>
								<div className='dropdown-list'>
									{listItem.children.map((dropdownItem, index) => {
										return (
											<div
												key={index}
												className={`dropdown-link-wrap ${index === 0 ? 'first' : ''} ${index + 1 === listItem.children.length ? 'last' : ''}`}>
												<CustomLink
													url={dropdownItem.url}
													className={`dropdown-item`}>
													<Text
														text={dropdownItem.name}
														className={`h4 font-futura-medium dropdown-item-text`}
													/>

													{dropdownItem.icon ? (
														<CustomImage
															src={dropdownItem.icon.src}
															className={'dropdown-item-icon image'}
														/>
													) : (
														<Icon
															name={'smile'}
															className={'h2-L dropdown-item-icon'}
														/>
													)}
												</CustomLink>
											</div>
										);
									})}
								</div>
							</div>
						) : null}
					</li>
				) : (
					<li
						key={index}
						className='list-item'>
						<CustomLink url={listItem.path}>
							<Text
								text={listItem.name}
								className={`h4 font-futura-medium hoverEveryText ${listItem?.hasColor ? 'colorTextIndividualHeaderBurger' : ''}`}
							/>
						</CustomLink>
					</li>
				);
			});
		},
		[listData, isDesktopSize]
	);

	const onFinish = (values) => {
		return login(values);
	};

	useEffect(() => {
		setIsLoginDropdownActive(false);
	}, [isLoggedIn]);

	//! Cart popup logic
	const isShow = useMemo(() => {
		return !(pathname.includes(config.routes.cart.path) || pathname.includes(config.routes.checkout.path) || !(items.length > 0));
	}, [pathname, items]);

	const openCartListPopup = useCallback(() => {
		setListCartPopup(true);

		document.body.style.cssText = ` overflow-y: hidden `;
	}, [listCartPopup, pathname]);

	const closeCartListPopup = useCallback(() => {
		setListCartPopup(false);

		document.body.style.cssText = ` overflow-y: auto `;
	}, [listCartPopup, pathname]);

	useEffect(() => {
		setListCartPopup(false);

		if (!isBurgerMenuOpen) {
			document.body.style.cssText = ` overflow-y: auto `;
		}
	}, [pathname, isBurgerMenuOpen]);

	useEffect(() => {
		if (isBurgerMenuOpen) {
			document.body.style.cssText = ` overflow-y: hidden `;
		}
	}, [isBurgerMenuOpen]);

	useEffect(() => {
		if (!isShow) {
			document.body.style.cssText = ` overflow-y: auto `;
		}
	}, [isShow]);

	return (
		<HeaderStyle className={`header ${listCartPopup && isDesktopSize && isShow ? 'popupCartActive' : ''} ${isDropdownActive ? 'dropdown-active' : 'dropdown-inactive'} ${isSearchDropdownActive ? 'search-dropdown-active' : ''} ${isLoginDropdownActive && checkSmile ? 'login-dropdown-active' : 'login-dropdown-inactive'}`}>
			<div className='all-wrapper'>
				{!isDesktopSize ? (
					<div className='burger-menu-wrap'>
						<Icon
							onClick={() => toggleBurgerMenu()}
							className={'menu-icon'}
							name={'menu'}
						/>
					</div>
				) : null}

				<CustomLink
					className={'logo-link'}
					url={config.routes.home.path}>
					<Image
						src={'/images/logo.svg'}
						className={'logo'}
					/>
				</CustomLink>

				<ul className='header-menu-list'>{storeListItems('header')}</ul>

				<div className='account-info'>
					<SearchPopup
						translate={translate}
						closeBurgerMenu={closeBurgerMenu}
						isSearchDropdownActive={isSearchDropdownActive}
						setIsSearchDropdownActive={setIsSearchDropdownActive}
					/>
					<div
						onMouseLeave={() => setIsLoginDropdownActive(false)}
						onMouseEnter={() => setIsLoginDropdownActive(!isLoggedIn)}
						className={`login-wrap ${isLoggedIn ? 'is-login' : ''} ${isLoggedIn || pathname.includes(config.routes.login.path) ? 'not-before' : ''}`}>
						<CustomLink
							ariaLabel={'login-smile-icon'}
							url={isLoggedIn ? config.routes.account.path : config.routes.login.path}
							className={`face-icon-link ${(!checkSmile && !isLoggedIn) || pathname.includes(config.routes.account.path) ? 'cursor-none' : ''}`}>
							<div className='circle'>
								<div
									ref={faceRef}
									className='face'>
									<div className='eyes'>
										<span></span>
										<span></span>
									</div>

									<div className='mouth'>
										<span></span>
									</div>
								</div>
							</div>
						</CustomLink>

						{!isLoggedIn && checkSmile ? (
							<div className='login-dropdown'>
								<Text
									className={'h2-L font-futura-medium dropdown-upper-title'}
									text={'loginToYour'}
								/>

								<Text
									className={'h2-L font-futura-regular dropdown-lower-title'}
									text={'zoyasAccount'}
								/>

								<FormContainer onFinish={onFinish}>
									<div className='inputs-wrap'>
										<div className={'email-wrap'}>
											<FormItem
												name={'email'}
												validationType={'email'}>
												<FormInput placeholder={`${translate('email')}*`} />
											</FormItem>
										</div>

										<div className='password-wrap'>
											<FormItem
												name='password'
												validationType='password'
												className={'password-field'}>
												<FormInput
													type='password'
													placeholder={`${translate('password')}*`}
												/>
											</FormItem>
										</div>
									</div>

									<div className='flex-end-wrapper'>
										<CustomLink
											className={'forgot-link'}
											url={config.routes.forgotPassword.path}>
											<Text
												className={'h6 font-futura-medium darkGrey'}
												text={'forgot'}
											/>
										</CustomLink>
									</div>

									<div className='login-and-create-wrap'>
										<CustomLink url={config.routes.register.path}>
											<Text
												className={'h5 font-futura-semibold darkGrey create-account'}
												text={'createAnAccount'}
											/>
										</CustomLink>

										<Button
											text={'login'}
											type={'submit'}
										/>
									</div>
								</FormContainer>
							</div>
						) : null}
					</div>

					<CustomLink
						ariaLabel={'favorite-icon'}
						className={'heart-icon-link'}
						url={config.routes.wishlist.path}>
						<Icon
							className={'h2'}
							name={pathname.includes(config.routes.wishlist.path) ? 'heart-fill' : 'heart'}>
							<span className='path1' />
							<span className='path2' />
						</Icon>
					</CustomLink>

					<div
						className={`cart-icon-link openCartItemsPopup ${!listCartPopup ? 'is-hide' : ''}`}
						onMouseEnter={isShow ? (!isTouch && isDesktopSize ? openCartListPopup : null) : null}
						onMouseLeave={isShow ? (!isTouch && isDesktopSize ? closeCartListPopup : null) : null}>
						<CustomLink url={config.routes.cart.path}>
							<div className={`cart-circle`}>
								<Text
									className={'black h4 font-futura-medium'}
									text={`${itemsCount}`}
								/>
							</div>
						</CustomLink>

						{isShow ? !isTouch && isDesktopSize ? <CartListPopup /> : null : null}
					</div>
				</div>
			</div>

			{!isDesktopSize && isBurgerMenuOpen ? (
				<BurgerMenu
					isLoggedIn={isLoggedIn}
					storeListItems={storeListItems('burger')}
				/>
			) : null}
		</HeaderStyle>
	);
};

export default withLanguageContext(withDataContext(withUIContext(withUserContext(withCartContext(AppHeader, ['itemsCount', 'items']), ['isLoggedIn', 'login']), ['winWidth', 'winHeight', 'toggleBurgerMenu', 'closeBurgerMenu', 'isBurgerMenuOpen']), ['globalData']), ['translate']);
