import styled from 'styled-components';

const HeaderStyle = styled.header`
	--headerPadding: var(--sp4x) var(--sp14x);
	--logoWidth: var(--sp18x);
	--logoPaddingTop: var(--sp6x);
	--listItemMarginLeftRight: var(--sp9x);
	--cartCircleSizes: var(--sp6x);
	--cartCircleMarginLeft: var(--sp6x);
	--menuIconSizes: var(--sp3x);
	--dropdownPadding: 0 var(--sp13x);
	--dropdownPositionTop: calc(100% + 44px);
	--dropdownItemMarginTop: var(--sp4x);
	--dropdownItemPaddingRight: var(--sp13x);
	--dropdownPositionLeft: calc(var(--sp13x) * -1);
	--dropdownWidth: 70vw;
	--searchDropdownLeftRight: var(--sp15x);
	--searchDropdownHeight: var(--sp11x);

	--loginDropdownPadding: var(--sp15x);
	--loginDropdownLowerTitleMarginBottom: var(--sp8x);
	--passwordFieldMarginLeft: var(--sp4x);
	--loginAndCreateWrapMarginTop: var(--sp7x);

	--loginSecondVersionWidth: var(--sp88x);

	--marginListItemMTop: var(--sp5x);

	background-color: var(--lightWhite);
	transition: background-color var(--trTime) ease-out;

	.burgerMenuWrapper {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0px;
		left: 0px;
		background-color: var(--lightWhite);
		z-index: 4;

		.burger-menu {
			height: calc(100% - var(--headerFixHeight));
			margin-top: var(--headerFixHeight);
			padding: 10vh 10vw;
		}

		.burger-menu-list {
			overflow-y: scroll;
			overflow-x: hidden;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	.all-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: var(--headerPadding);
		position: relative;
	}

	.burger-menu-wrap {
		width: 0;
	}

	& + main {
		min-height: calc(100dvh - var(--headerFixHeight));

		.account-main-wrapper {
			display: flex;
			min-height: calc(100dvh - var(--headerFixHeight));
		}
	}

	&.dropdown-inactive {
		.dropdown-item {
			transition: unset !important;
		}
	}

	&.search-dropdown-active {
		pointer-events: none;

		& + main {
			pointer-events: none;

			&::before {
				position: fixed;
				opacity: 1;
			}
		}

		& ~ .footer,
		& ~ .footer-fake-height {
			pointer-events: none;
		}
	}

	&.login-dropdown-active {
		position: relative;
		z-index: 15;
		background-color: var(--white);

		& + main {
			&::before {
				position: fixed;
				opacity: 1;
			}
		}
	}

	&.login-dropdown-inactive {
		.login-dropdown {
			.dropdown-lower-title,
			.dropdown-upper-title,
			.login-and-create-wrap,
			.inputs-wrap,
			.flex-end-wrapper {
				transition: unset !important;
			}
		}
	}

	&.popupCartActive {
		@media (hover: hover) {
			position: relative;
			z-index: 15;
			background-color: var(--white);

			& + main {
				&::before {
					position: fixed;
					opacity: 1;
				}
			}
		}
	}

	&.dropdown-active {
		position: relative;
		z-index: 15;
		background-color: var(--white);

		& + main {
			&::before {
				position: fixed;
				opacity: 1;
			}
		}

		.list-item {
			&.with-dropdown {
				.header-menu-item {
					color: var(--coral);
				}

				.menu-icon {
					color: var(--coral);
					transform: rotate(180deg);
				}

				.dropdown-list {
					.dropdown-link-wrap {
						.dropdown-item {
							transform: translateY(0px);
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.logo-link {
		width: var(--logoWidth);
		z-index: 11;

		.logo {
			position: relative;
			padding-top: var(--logoPaddingTop);

			img {
				object-fit: contain;
			}
		}
	}

	.login-dropdown {
		.ant-form {
			width: var(--loginSecondVersionWidth);
		}
	}

	.inputs-wrap {
		gap: var(--passwordFieldMarginLeft);
	}

	.email-wrap,
	.password-wrap {
		width: 50%;
	}

	.menu-icon {
		position: relative;
		z-index: 11;
		display: block !important;
		font-size: var(--menuIconSizes);
		cursor: pointer;
	}

	.header-menu-list {
		display: flex;

		.header-menu-item {
			transition: color var(--trTime) ease-out;
		}

		.menu-icon {
			transition: opacity var(--trTime) ease-out;
		}
	}

	.list-item {
		position: relative;
		margin: 0 calc(var(--listItemMarginLeftRight) / 2);

		&.with-dropdown {
			@media (hover: hover) {
				&:hover {
					.dropdown {
						max-height: 100vh;
						pointer-events: all;
					}

					&:before {
						content: '';
						position: absolute;
						top: 100%;
						left: 0;
						width: 50vw;
						height: var(--sp7x);
					}
				}
			}
		}

		.dropdown {
			position: absolute;
			width: var(--dropdownWidth);
			top: var(--dropdownPositionTop);
			left: var(--dropdownPositionLeft);
			z-index: 10;
			background-color: var(--white);
			padding: var(--dropdownPadding);
			overflow: hidden;
			pointer-events: none;
			max-height: 0px;
			transition: var(--trTime) ease-out;

			.dropdown-list {
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;
				margin-top: calc(var(--dropdownItemMarginTop) * -1);

				.dropdown-link-wrap {
					width: calc(100% / 4);
					padding-right: var(--dropdownItemPaddingRight);
					margin-top: var(--dropdownItemMarginTop);

					&.first {
						padding-top: var(--sp8x);
					}

					&.last {
						padding-bottom: var(--sp8x);
					}

					&:nth-child(-n + 24) {
						.dropdown-item {
							transition: var(--trTime) ease-out 0.2s;
						}
					}

					&:nth-child(-n + 20) {
						.dropdown-item {
							transition: var(--trTime) ease-out 0.4s;
						}
					}

					&:nth-child(-n + 16) {
						.dropdown-item {
							transition: var(--trTime) ease-out 0.6s;
						}
					}

					&:nth-child(-n + 12) {
						.dropdown-item {
							transition: var(--trTime) ease-out 0.8s;
						}
					}

					&:nth-child(-n + 8) {
						.dropdown-item {
							transition: var(--trTime) ease-out 1s;
						}
					}

					&:nth-child(-n + 4) {
						.dropdown-item {
							transition: var(--trTime) ease-out 1.2s;
						}
					}

					&:nth-child(-n) {
						.dropdown-item {
							transition: var(--trTime) ease-out 1.4s;
						}
					}

					.dropdown-item {
						width: fit-content;
						transform: translateY(-50px);
						opacity: 0;

						@media (hover: hover) {
							&:hover {
								.dropdown-item-text {
									color: var(--green);
								}

								.dropdown-item-icon {
									opacity: 1;
								}
							}
						}

						.dropdown-item-text {
							transition: var(--trTime) ease-out;
						}

						.dropdown-item-icon {
							margin-left: var(--sp2x);
							transition: var(--trTime) ease-out;
							opacity: 0;

							&.image {
								width: var(--h2L);
								height: var(--h2L);
								padding-top: var(--h2L);

								img {
									object-fit: contain;
								}
							}
						}
					}
				}
			}
		}

		&.external {
			i,
			.circle {
				margin-left: var(--sp3x);
			}
		}

		.link-item {
			display: flex;
			align-items: center;

			.menu-icon {
				font-size: 10px;
				margin-left: var(--sp2x);
			}

			.hoverEveryText {
				transition: var(--trTime) ease-out;

				@media (hover: hover) {
					&:hover {
						color: var(--coral);
					}
				}
			}

			.colorTextIndividualHeaderBurger {
				color: var(--green);
				transition: var(--trTime) ease-out;

				@media (hover: hover) {
					&:hover {
						color: var(--black);
					}
				}
			}
		}
	}

	.circle {
		position: relative;
		width: var(--sp3x);
		height: var(--sp3x);
		border-radius: 50%;
		border: 2px solid var(--black);

		.face {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			.eyes {
				position: absolute;
				width: 12px;
				top: 40%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: 3px;
				display: flex;
				justify-content: space-between;

				span {
					width: 3px;
					height: 3px;
					background-color: var(--black);
					border-radius: 50%;
					transform: rotateX(0deg);
				}
			}

			.mouth {
				position: absolute;
				z-index: 1;
				top: 60%;
				left: 0;
				right: 0;
				width: 12px;
				height: 4px;
				margin: 0 auto;
				overflow: hidden;

				span {
					width: 12px;
					height: 12px;
					position: absolute;
					bottom: 0;
					left: 0;
					border: 1px solid var(--black);
					border-radius: 50%;
					overflow: hidden;
				}
			}
		}
	}

	.account-info {
		z-index: 11;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.search-wrap {
			cursor: pointer;

			.search-icon {
				transition: var(--trTime) ease-out;
			}

			.search-dropdown {
				position: absolute;
				top: 100%;
				right: var(--searchDropdownLeftRight);
				left: var(--searchDropdownLeftRight);
				width: calc(100vw - calc(var(--searchDropdownLeftRight)) * 2);
				height: var(--searchDropdownHeight);
				pointer-events: none;
				max-height: 0;
				transition: var(--trTime) ease-out;
				cursor: default;
				overflow: hidden;

				.ant-input {
					border: none;
					width: 100%;
					height: 100%;
					font-size: var(--h4);
					font-family: var(--futura);
					font-weight: 500;
					text-align: center;
					padding: 0 var(--sp3x);
					background-color: var(--white);

					&::placeholder {
						color: var(--black);
					}
				}
			}

			@media (hover: hover) {
				&:hover {
					.search-icon {
						color: var(--green);
					}
				}
			}

			&.show {
				pointer-events: all;

				.search-dropdown {
					max-height: 100px;
					pointer-events: all;
				}

				.search-icon {
					color: var(--green);
				}
			}
		}

		.login-wrap {
			margin-left: var(--sp4x);

			&:not(&.not-before) {
				@media (hover: hover) {
					&:hover {
						.login-dropdown {
							max-height: 500px;

							.dropdown-lower-title,
							.dropdown-upper-title,
							.login-and-create-wrap,
							.inputs-wrap,
							.flex-end-wrapper {
								transform: translateY(0);
								opacity: 1;
							}
						}

						&::before {
							content: '';
							position: absolute;
							top: 60%;
							left: 0;
							width: 100%;
							height: var(--sp8x);
						}
					}
				}
			}

			.login-dropdown {
				position: absolute;
				top: 99%;
				right: 0;
				background-color: var(--white);
				padding: 0 var(--loginDropdownPadding);
				max-height: 0;
				overflow: hidden;
				transition: var(--trTime) ease-out;
				cursor: default;

				.dropdown-lower-title {
					margin-bottom: var(--loginDropdownLowerTitleMarginBottom);
					transform: translateY(-50px);
					transition: var(--trTime) ease-out;
					transition-delay: 0.6s;
					opacity: 0;
				}

				.dropdown-upper-title {
					margin-top: var(--loginDropdownPadding);
					transform: translateY(-50px);
					transition: var(--trTime) ease-out;
					transition-delay: 0.8s;
					opacity: 0;
				}

				.password-wrap {
					position: relative;

					.forgot-link {
						position: absolute;
						top: 0;
						right: 0;
					}
				}

				.login-and-create-wrap {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: var(--loginAndCreateWrapMarginTop);
					margin-bottom: var(--loginDropdownPadding);
					transform: translateY(-50px);
					transition: var(--trTime) ease-out;
					transition-delay: 0.2s;
					opacity: 0;

					.create-account {
						text-decoration: underline;
					}
				}
			}

			.ant-form {
				display: flex;
				flex-direction: column;

				.inputs-wrap {
					display: flex;
					transform: translateY(-50px);
					transition: var(--trTime) ease-out;
					transition-delay: 0.4s;
					opacity: 0;
				}
			}
		}

		.heart-icon-link {
			margin-left: var(--sp4x);
		}

		.cart-icon-link {
			margin-left: var(--cartCircleMarginLeft);

			.cart-circle {
				display: flex;
				justify-content: center;
				align-items: center;
				width: var(--cartCircleSizes);
				height: var(--cartCircleSizes);
				background-color: var(--lightGreen);
				border-radius: 50%;
			}
		}
	}

	.burger-menu {
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10;

		.burger-menu-list {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;

			.list-item {
				&:not(:first-child) {
					margin-top: var(--sp6x);
				}
			}
		}
	}

	.flex-end-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: var(--sp1x);
		transform: translateY(-50px);
		transition: var(--trTime) ease-out;
		transition-delay: 0.4s;
		opacity: 0;
	}

	.face-icon-link {
		&.cursor-none {
			pointer-events: none;
			cursor: default;
		}
	}

	.login-wrap,
	.mobile-login-wrap {
		&.is-login {
			.circle {
				background-color: var(--green);
				border-color: var(--green);
			}

			.eyes {
				span {
					background-color: var(--white);
				}
			}

			.mouth {
				span {
					border-color: var(--white);
				}
			}
		}
	}

	.openCartItemsPopup {
		position: relative;

		.cart-list-popup {
			transform: translateX(100%);
			transition: transform var(--trTime) ease-out;
		}

		@media (hover: hover) {
			&:not(&.is-hide):hover {
				.cart-list-popup {
					transform: translateX(0);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--headerPadding: var(--sp3x) var(--sp5x);
		--logoWidth: var(--sp13x);
		--logoPaddingTop: var(--sp5x);
		--cartCircleMarginLeft: var(--sp7x);
		--dropdownPadding: 0 var(--sp9x);
		--dropdownPositionTop: calc(100% + 37px);
		--dropdownItemMarginTop: var(--sp4x);
		--dropdownItemPaddingRight: var(--sp13x);
		--dropdownPositionLeft: calc(var(--sp8x) * -1);
		--dropdownWidth: 77vw;
		--searchDropdownLeftRight: var(--sp6x);
		--searchDropdownHeight: var(--sp10x);
		--loginDropdownPadding: var(--sp10x);
		--passwordFieldMarginLeft: var(--sp4x);

		--loginSecondVersionWidth: var(--sp70x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--headerPadding: var(--sp3x) var(--sp5x);
		--logoWidth: var(--sp13x);
		--logoPaddingTop: var(--sp5x);
		--cartCircleMarginLeft: var(--sp8x);
		--dropdownPadding: 0 var(--sp8x);
		--dropdownPositionTop: calc(100% + 37px);
		--dropdownItemMarginTop: var(--sp4x);
		--dropdownItemPaddingRight: var(--sp8x);
		--dropdownPositionLeft: calc(var(--sp8x) * -1);
		--dropdownWidth: 82vw;
		--searchDropdownLeftRight: var(--sp5x);
		--searchDropdownHeight: var(--sp9x);
		--loginDropdownPadding: var(--sp8x);
		--passwordFieldMarginLeft: var(--sp2-5x);

		--loginSecondVersionWidth: var(--sp64x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--headerPadding: var(--sp3x) var(--sp4x);
		--logoWidth: var(--sp13x);
		--logoPaddingTop: var(--sp5x);
		--cartCircleMarginLeft: var(--sp4x);
		--searchDropdownLeftRight: var(--sp4x);
		--searchDropdownHeight: var(--sp7x);

		.all-wrapper {
			position: relative;
			display: grid;
			grid-template-columns: 1fr auto;
		}

		.logo-link {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.face-icon-link,
		.heart-icon-link,
		.login-wrap,
		.header-menu-list {
			display: none;
		}

		.mobile-icon-size {
			font-size: var(--sp2-5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--headerPadding: var(--sp3x);
		--logoWidth: var(--sp13x);
		--logoPaddingTop: var(--sp5x);
		--cartCircleMarginLeft: var(--sp4x);
		--searchDropdownLeftRight: var(--sp3x);
		--searchDropdownHeight: var(--sp7x);

		.all-wrapper {
			position: relative;
			display: grid;
			grid-template-columns: 1fr auto;
		}

		.logo-link {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.face-icon-link,
		.heart-icon-link,
		.login-wrap,
		.header-menu-list {
			display: none;
		}

		.mobile-icon-size {
			font-size: var(--sp2-5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--headerPadding: var(--sp1x) var(--sp3x);
		--logoWidth: var(--sp9x);
		--logoPaddingTop: var(--sp3-5x);
		--cartCircleSizes: var(--sp5x);
		--cartCircleMarginLeft: var(--sp2-5x);
		--searchDropdownLeftRight: var(--sp2x);
		--searchDropdownHeight: var(--sp6x);

		.all-wrapper {
			position: relative;
			display: grid;
			grid-template-columns: 1fr auto;
		}

		.logo-link {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.face-icon-link,
		.heart-icon-link,
		.login-wrap,
		.header-menu-list {
			display: none;
		}

		.mobile-icon-size {
			font-size: var(--sp2-5x);
		}
	}
`;

export default HeaderStyle;
