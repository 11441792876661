//* HOC's
import { withCartContext, withFavoriteContext } from '@/context';

//* Components
import Text from '@/components/common/Text';
import Image from '@/components/common/Image';
import Price from '@/components/common/Price';
import Counter from '@/components/common/Counter';

//* Style
import CartItemPopupStyle from './style';

const CartItemPopup = ({ id, image, category, name, sale_price, price, qty, deleteCartItem, toggleCartItem, toggleFavorite, checkActiveFavorite, url }) => {
    return (
        <CartItemPopupStyle>
            <div className='wrapperInfoProduct'>
                <div className='product-wrap'>
                    <a
                        href={url}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='product-image'>
                        <Image
                            src={image.src}
                            alt={image.alt}
                            className={'product-image'}
                        />
                    </a>

                    <div className='info-wrap'>
                        <Text
                            className={'h5 darkGrey font-futura-regular'}
                            text={category}
                        />

                        <a
                            href={url}
                            target='_blank'
                            rel='noopener noreferrer'>
                            <Text
                                className={'h4 font-futura-medium name'}
                                text={name}
                            />
                        </a>
                    </div>
                </div>

                <div className='counter-wrap'>
                    <Counter
                        qty={qty}
                        onChange={(newQty) => toggleCartItem({ productId: id, qty: newQty })}
                    />
                </div>

                <Price
                    price={price}
                    sale_price={sale_price}
                    className={'h3 item-price'}
                />
            </div>

            <div className='productWrapperAddDelete'>
                <Text
                    text={'delete'}
                    onClick={() => deleteCartItem({ productId: id })}
                    className='h5 font-futura-medium underline delete-button cursor-pointer'
                />

                <Text
                    onClick={() => toggleFavorite({ productId: id })}
                    className={'h5 underline font-futura-regular cursor-pointer fit-content'}
                    text={checkActiveFavorite({ productId: id }) ? 'removeFromFavorites' : 'addToFavorite'}
                />
            </div>
        </CartItemPopupStyle>
    );
};

export default withCartContext(withFavoriteContext(CartItemPopup, ['toggleFavorite', 'checkActiveFavorite']), ['deleteCartItem', 'toggleCartItem']);
