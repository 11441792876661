import { useMemo } from 'react';

//* HOC's
import { withCartContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import CustomLink from '../../CustomLink';
import CartItemPopup from './CartItemPopup';
import Text from '@/components/common/Text';
import Price from '@/components/common/Price';
import Button from '@/components/common/Button';
import Container from '@/components/common/Container';

//* Style
import CartListPopupStyle from './style';

const CartListPopup = ({ items, subtotal }) => {
	//! Items
	const storeCartItems = useMemo(() => {
		return items?.length > 0
			? items.map((item, index) => {
					return (
						<CartItemPopup
							key={index}
							qty={item.qty}
							total={item.total}
							{...item.product}
						/>
					);
			  })
			: null;
	}, [items]);

	return (
		<CartListPopupStyle className='cart-list-popup'>
			<Container className={'cart-container'}>
				<div className='cart-top'>
					<div className='underLineBag'>
						<Text
							text={'myBag'}
							className={'h2-L font-futura-medium title'}
						/>
					</div>

					<div className='listing-body'>{storeCartItems}</div>
				</div>

				<div className='cart-bottom'>
					<div className='cart-subtotal'>
						<Text
							className={'h4 font-futura-medium'}
							text={'subtotal'}
						/>

						<Price price={subtotal} />
					</div>

					<div className='buttonCheckout'>
						<CustomLink url={config.routes.shop.path}>
							<Text
								className={'h5 font-futura-semibold underline darkGrey'}
								text={'continueShopping'}
							/>
						</CustomLink>

						<Button
							text={'proceedToCheckout'}
							url={config.routes.checkout.path}
						/>
					</div>
				</div>
			</Container>
		</CartListPopupStyle>
	);
};

export default withCartContext(CartListPopup, ['items', 'subtotal']);
