'use client';

import { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { usePathname } from 'next/navigation';

//* HOC's
import { withUIContext, withDataContext, withLanguageContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '@/components/common/Text';
import Container from '@/components/common/Container';
import CustomLink from '@/components/common/CustomLink';

//* Style
import FooterStyle from './style';

const AppFooter = ({ globalData, translate, winWidth }) => {
	//! State
	const [footerHeight, setFooterHeight] = useState();

	//! Refs
	const footerRef = useRef();
	const layerRef = useRef();

	//! Pathname
	const pathname = usePathname();

	const storeRoutes = useMemo(() => {
		const routes = Object.values(config.routes).filter((item) => item.store);

		return routes.map((item, index) => {
			return (
				<CustomLink
					key={index}
					url={item.path}
					className={'footer-menu-link'}>
					<Text
						className={'h5 white font-futura-regular'}
						text={item.name}
					/>
				</CustomLink>
			);
		});
	}, []);

	const storeForCustomerRoutes = useMemo(() => {
		const routes = Object.values(config.routes).filter((item) => item.forCustomer);

		return routes.map((item, index) => {
			return (
				<CustomLink
					key={index}
					url={item.path}
					className={'footer-menu-link'}>
					<Text
						className={'h5 white font-futura-regular'}
						text={item.name}
					/>
				</CustomLink>
			);
		});
	}, []);

	useEffect(() => {
		const footerCountHeight = footerRef.current.getBoundingClientRect().height;

		setFooterHeight(footerCountHeight);
	}, [winWidth, pathname, footerHeight]);

	const onScroll = useCallback(() => {
		const footer = document.querySelector('footer');
		const layer = layerRef.current;
		const ftrOfsTop = footer.offsetTop;
		const winHgt = window.innerHeight;
		const winScTop = window.scrollY;

		if (winScTop + winHgt > ftrOfsTop) {
			let diff = ftrOfsTop - (winScTop + winHgt);

			if (window.innerWidth < 901) {
				diff = diff * 1.2;
			}

			if (layer != undefined) {
				layer.style.transform = `translateY(${diff}px)`;
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', () => onScroll());

		return window.removeEventListener('scroll', () => onScroll());
	}, []);

	return (
		<>
			{pathname.includes('login') || pathname.includes('forgot-password') || pathname.includes('reset-password') || pathname.includes('register') || pathname.includes('account') ? null : (
				<div
					className={`footer-fake-height`}
					style={{ paddingTop: `${footerHeight}px` }}></div>
			)}

			<FooterStyle
				ref={footerRef}
				className={`footer ${pathname.includes('login') || pathname.includes('forgot-password') || pathname.includes('reset-password') || pathname.includes('register') || pathname.includes('account') ? 'disable' : ''}`}>
				<Container row>
					<div className='col-2 col-ts-4'>
						<Text
							className={'h1 footer-column-title font-futura-medium pink'}
							text={'store'}
						/>
						{storeRoutes}
					</div>

					<div className='col-2 col-ts-4'>
						<Text
							className={'h1 footer-column-title font-futura-medium pink'}
							text={'forCustomer'}
						/>
						{storeForCustomerRoutes}
					</div>

					<div className='col-2 col-ts-4 col-m-8 col-contact-us'>
						<Text
							className={'h1 footer-column-title font-futura-medium pink'}
							text={'contactUs'}
						/>

						<Text className={'h5 font-futura-regular white phone'}>
							{translate('phone')}:{' '}
							<a
								className='font-futura-medium'
								href={`tel:${globalData.phone}`}
								rel='noopener noreferrer'>
								{globalData.phone}
							</a>
						</Text>

						<Text className={'h5 font-futura-regular white email'}>
							{translate('email')}:{' '}
							<a
								className='font-futura-medium'
								href={`mailto:${globalData.email}`}
								rel='noopener noreferrer'>
								{globalData.email}
							</a>
						</Text>

						<Text
							className={'h5 font-futura-regular white'}
							text={globalData.contact_information}
						/>
					</div>

					<div className='col-2 col-ts-4 col-m-8 col-follow-us'>
						<Text
							className={'h1 footer-column-title font-futura-medium pink'}
							text={'followUs'}
						/>
						<a
							className='h5 font-futura-medium social-link facebook white'
							href={globalData.socials.facebook}
							rel='noopener noreferrer'
							target='_blank'>
							Facebook
						</a>
						<a
							className='h5 font-futura-medium social-link white'
							href={globalData.socials.instagram}
							rel='noopener noreferrer'
							target='_blank'>
							Instagram
						</a>
					</div>
				</Container>
				<Container>
					<svg
						className='paypal'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 62.619 16.688'>
						<g
							id='Group_3801'
							data-name='Group 3801'
							transform='translate(-166.462 -30.57)'>
							<path
								id='Path_1980'
								data-name='Path 1980'
								d='M174.468,30.59c2.044,0,3.93,1.109,3.672,3.872-.313,3.284-2.072,5.1-4.848,5.109h-2.425c-.349,0-.518.285-.608.868l-.469,2.982c-.07.45-.3.672-.643.672h-2.256c-.36,0-.485-.23-.4-.744L168.348,31.4c.092-.588.313-.807.715-.807h5.406Zm-3.675,6.4h1.837c1.149-.044,1.912-.84,1.989-2.275a1.388,1.388,0,0,0-1.5-1.517l-1.729.008-.593,3.784Zm13.481,6.189c.206-.188.416-.285.386-.053l-.073.554c-.038.289.077.443.346.443h2c.338,0,.5-.136.585-.658l1.235-7.753c.063-.389-.033-.58-.328-.58h-2.2c-.2,0-.3.111-.347.414l-.081.477c-.042.249-.156.292-.263.042-.374-.885-1.328-1.282-2.658-1.251a5.518,5.518,0,0,0-5.4,5.42,3.726,3.726,0,0,0,3.694,4.155,3.965,3.965,0,0,0,3.112-1.2h0ZM182.6,41.985a2.015,2.015,0,0,1-2.066-2.363,2.878,2.878,0,0,1,2.768-2.363,2.015,2.015,0,0,1,2.066,2.363,2.877,2.877,0,0,1-2.768,2.363Zm10.086-6.88h-2.033c-.419,0-.59.313-.457.7l2.524,7.39-2.475,3.517c-.208.294-.047.561.246.561h2.285a.7.7,0,0,0,.679-.333L201.211,35.8c.239-.342.127-.7-.266-.7h-2.163c-.371,0-.519.147-.732.455l-3.237,4.691-1.446-4.7a.619.619,0,0,0-.685-.441Z'
								transform='translate(0 -0.017)'
								fill='#fff'></path>
							<path
								id='Path_1981'
								data-name='Path 1981'
								d='M397.137,30.573c2.044,0,3.93,1.109,3.672,3.872-.313,3.284-2.072,5.1-4.848,5.109h-2.424c-.349,0-.518.285-.608.868L392.46,43.4c-.07.45-.3.672-.643.672h-2.256c-.36,0-.485-.23-.4-.744l1.866-11.955c.092-.588.313-.807.715-.807h5.4Zm-3.675,6.4H395.3c1.149-.044,1.912-.84,1.989-2.275a1.388,1.388,0,0,0-1.5-1.517l-1.729.008-.593,3.784Zm13.481,6.189c.206-.188.416-.285.386-.053l-.073.554c-.038.289.077.443.346.443h2c.338,0,.5-.136.585-.658l1.235-7.753c.063-.389-.033-.58-.328-.58h-2.2c-.2,0-.3.111-.347.414l-.081.477c-.042.249-.156.292-.263.042-.374-.885-1.328-1.282-2.658-1.251a5.518,5.518,0,0,0-5.4,5.42,3.726,3.726,0,0,0,3.694,4.155,3.965,3.965,0,0,0,3.112-1.2h0Zm-1.678-1.193a2.015,2.015,0,0,1-2.066-2.363,2.878,2.878,0,0,1,2.768-2.363,2.015,2.015,0,0,1,2.066,2.363,2.878,2.878,0,0,1-2.768,2.363Zm9.246,2.149H412.2a.274.274,0,0,1-.286-.261.27.27,0,0,1,0-.052l2.033-12.879a.4.4,0,0,1,.385-.313h2.314a.274.274,0,0,1,.286.261.286.286,0,0,1,0,.052L414.9,43.8a.4.4,0,0,1-.385.316Z'
								transform='translate(-187.85)'
								fill='#fff'></path>
						</g>
					</svg>

					<div className='rights-wrap'>
						<Text className={'h5 font-futura-regular white'}>
							{translate('allRights')} © {new Date().getFullYear()}
						</Text>

						<Text className={'h5 font-futura-regular white concept-info'}>
							{translate('designedAndDevelopedBy')}{' '}
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://conceptstudio.com/'
								className='font-futura-semibold uppercase'>
								Concept Studio
							</a>
						</Text>
					</div>
				</Container>

				{pathname.includes('login') || pathname.includes('forgot-password') || pathname.includes('reset-password') || pathname.includes('register') || pathname.includes('account') ? null : (
					<div
						ref={layerRef}
						className={`layer`}
					/>
				)}
			</FooterStyle>
		</>
	);
};

export default withUIContext(withLanguageContext(withDataContext(AppFooter, ['globalData']), ['translate']), ['winWidth']);
