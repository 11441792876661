import { useState } from 'react';

//* Style
import CounterStyle from './style';

//* Components
import Text from '@/components/common/Text';
import Icon from '@/components/common/Icon';

const Counter = ({ qty, onChange }) => {
	//! States
	const [count, setCount] = useState(qty || 1);

	const handleChange = (newCount) => {
		setCount(newCount);
		onChange && onChange(newCount);
	};

	return (
		<CounterStyle>
			<div className='counter'>
				<Icon
					className={`p green count-icon ${count === 1 ? 'disable' : ''}`}
					name={'minus'}
					onClick={() => handleChange(count - 1)}
				/>
				<Text className={'h2 font-futura-regular'}>{count}</Text>
				<Icon
					className={`p green count-icon ${count === 999 ? 'disable' : ''}`}
					name={'plus'}
					onClick={() => handleChange(count + 1)}
				/>
			</div>
		</CounterStyle>
	);
};

export default Counter;
