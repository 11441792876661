import styled from 'styled-components';

const CounterStyle = styled.div`
	--counterPadding: var(--sp1x) var(--sp3x);
	--counterWidth: var(--sp20x);
	--counterBorderRadius: var(--sp4x);

	display: flex;
	padding: var(--counterPadding);
	border: 2px solid var(--green);
	border-radius: var(--counterBorderRadius);
	width: var(--counterWidth);

	.counter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.count-icon {
			cursor: pointer;

			&.disable {
				pointer-events: none;
				opacity: 0.5;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--counterPadding: var(--sp1x) var(--sp3x);
		--counterWidth: var(--sp17x);
		--counterBorderRadius: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--counterPadding: var(--sp1x) var(--sp2-5x);
		--counterWidth: var(--sp15x);
		--counterBorderRadius: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--counterPadding: var(--sp1x) var(--sp2-5x);
		--counterWidth: var(--sp15x);
		--counterBorderRadius: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--counterPadding: var(--sp1x) var(--sp2-5x);
		--counterWidth: var(--sp15x);
		--counterBorderRadius: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--counterPadding: var(--sp1x) var(--sp2-5x);
		--counterWidth: var(--sp15x);
		--counterBorderRadius: var(--sp2-5x);
	}
`;

export default CounterStyle;
