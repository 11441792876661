import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/node_modules/swiper/swiper.min.css");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/components/common/AppFooter/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/components/common/AppHeader/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/components/common/Popup/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/context/providers/CartProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/context/providers/DataProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/context/providers/FavoriteProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/context/providers/LanguageProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/context/providers/UIProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/context/providers/UserProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/helpers/_useActiveSection.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/libs/styled-registry.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/zoyaspantry.com.au/httpdocs/frontend/src/styles/icons/style.css")