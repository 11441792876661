import { forwardRef, useId } from 'react';
import Link from 'next/link';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

const CustomLink = forwardRef(({ selectedLang, options, target, ...props }, ref) => {
	const id = useId();

	return (
		<Link
			id={id}
			ref={ref}
			target={target}
			rel={props.rel}
			href={props.url}
			style={props.style}
			scroll={props.scroll}
			shallow={props.shallow}
			onClick={props.onClick}
			onMouseOut={props.onMouseOut}
			onMouseOver={props.onMouseOver}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			className={`link-item ${props.className || ''}`}
			aria-label={props.ariaLabel || props.url.split('/').join(' ')}
			{...options}>
			{props.content || props.children}
		</Link>
	);
});

CustomLink.displayName = 'CustomLink';

export default withLanguageContext(CustomLink, ['selectedLang']);
