import styled from 'styled-components';

const CartItemPopupStyle = styled.div`
	--cartItemPadding: var(--sp2-5x) 0;
	--productImageSizes: var(--sp10x);
	--productImageMarginRight: var(--sp3x);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: var(--sp3x);
	padding: var(--cartItemPadding);
	border-bottom: 1px solid var(--darkGrey);

	.imageCont {
		display: block;
		padding-top: var(--productImageSizes);
		height: var(--productImageSizes);

		img {
			object-fit: contain;
		}
	}

	.fit-content {
		width: fit-content;
	}

	.product-wrap {
		display: flex;
		width: 45%;

		.product-image {
			width: var(--productImageSizes);
			min-width: var(--productImageSizes);
			height: fit-content;
			margin-right: var(--productImageMarginRight);
		}

		.name {
			margin: var(--sp1x) 0;
		}
	}

	.wrapperInfoProduct,
	.productWrapperAddDelete {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
`;

export default CartItemPopupStyle;
