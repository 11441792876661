//* Components
import Text from '@/components/common/Text';

const Price = ({ price, sale_price, className }) => {
	return (
		<Text className={`h4 font-futura-medium ${className || ''}`}>
			{price != '-' ? (
				sale_price ? (
					<span className='flex-column'>
						${sale_price}&nbsp;<del className='h6 font-futura-regular grey'>${price}</del>
					</span>
				) : (
					<span>${price}</span>
				)
			) : (
				price
			)}
		</Text>
	);
};

export default Price;
