import { usePathname } from 'next/navigation';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '../../Text';
import Icon from '../../Icon';
import CustomLink from '../../CustomLink';

const BurgerMenu = ({ storeListItems, isLoggedIn }) => {
	const pathname = usePathname();
	return (
		<div className='burgerMenuWrapper'>
			<div className={`burger-menu`}>
				<ul className='burger-menu-list'>
					{storeListItems}

					<li className='list-item external'>
						<CustomLink
							ariaLabel={'favorite-icon'}
							url={config.routes.wishlist.path}>
							<Text
								className={'h4 font-futura-medium'}
								text={'favorites'}
							/>

							<Icon
								className={'mobile-icon-size'}
								name={pathname.includes(config.routes.wishlist.path) ? 'heart-fill' : 'heart'}>
								<span className='path1' />
								<span className='path2' />
							</Icon>
						</CustomLink>
					</li>

					<li className={`list-item external mobile-login-wrap ${isLoggedIn ? 'is-login' : ''}`}>
						<CustomLink
							ariaLabel={'login-smile-icon'}
							url={isLoggedIn ? config.routes.account.path : config.routes.login.path}>
							<Text
								className={'h4 font-futura-medium'}
								text={isLoggedIn ? 'account' : 'login'}
							/>

							<div className='circle'>
								<div className='face'>
									<div className='eyes'>
										<span></span>
										<span></span>
									</div>
									<div className='mouth'>
										<span></span>
									</div>
								</div>
							</div>
						</CustomLink>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default BurgerMenu;
