import styled from 'styled-components';

const PopupStyle = styled.div`
	--popupMaxWidth: var(--sp87x);
	--popupMaxHeight: 100vh;
	--popupContentMaxHeight: 100%;
	--popupPadTop: var(--sp10x);
	--popupPadBot: var(--sp10x);
	--popupPadRL: var(--sp8x);

	position: fixed;
	width: 100vw;
	inset: 0;
	z-index: 100000;

	&::-webkit-scrollbar {
		display: none;
	}

	.popup-background {
		background-color: rgba(64, 38, 97, 0.3);
		pointer-events: all;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	@keyframes openPopup {
		from {
			transform: scale(0);
		}
		to {
			transform: scale(1);
		}
	}

	.popup-content-container {
		animation: openPopup 0.3s linear;
		max-width: var(--popupMaxWidth);
		max-height: var(--popupMaxHeight);
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		pointer-events: all;
		overflow: scroll;
		margin: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		.popup-content-wrap {
			position: relative;
			width: 100%;
			height: fit-content;
			max-height: var(--popupContentMaxHeight);
			padding: var(--popupPadTop) var(--popupPadRL) var(--popupPadBot) var(--popupPadRL);
			background: var(--lightWhite);
			box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.1);
			opacity: 1;
			overflow-x: hidden;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--popupPadTop: var(--sp8x);
		--popupPadBot: var(--sp2-5x);
		--popupPadRL: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--popupPadTop: var(--sp8x);
		--popupPadBot: var(--sp2-5x);
		--popupPadRL: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--popupPadTop: var(--sp8x);
		--popupPadBot: var(--sp2-5x);
		--popupPadRL: var(--sp5x);

		.popup-content-wrap {
			height: 100% !important;
			overflow-y: auto;
		}
	}
`;
export default PopupStyle;
