import styled from 'styled-components';

const FooterStyle = styled.footer`
	--footerPadding: var(--sp15x) 0 var(--sp6x) 0;
	--footerColumnTitleMarginBottom: var(--sp2x);
	--footerMenuLinkMarginBottom: var(--sp2x);
	--payPalMarginTopBottom: var(--sp8x) 0 var(--sp2-5x) 0;

	padding: var(--footerPadding);
	background-color: var(--purple);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 0;

	&.hidden {
		display: none;
	}

	.layer {
		position: absolute;
		top: -1px;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--lightWhite);
		z-index: 11;
		pointer-events: none;

		&.disable {
			display: none !important;
		}
	}

	&.disable {
		display: none !important;
	}

	.footer-column-title {
		margin-bottom: var(--footerColumnTitleMarginBottom);
	}

	.footer-menu-link {
		display: block;
		width: fit-content;
		margin-bottom: var(--footerMenuLinkMarginBottom);

		&:last-child {
			margin-bottom: unset;
		}
	}

	.phone,
	.email {
		margin-bottom: var(--footerMenuLinkMarginBottom);
	}

	.social-link {
		display: block;

		&.facebook {
			margin-bottom: var(--footerMenuLinkMarginBottom);
		}
	}

	.paypal {
		width: var(--sp8x);
		margin: var(--payPalMarginTopBottom);
	}

	.rights-wrap {
		display: flex;
		justify-content: space-between;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--footerPadding: var(--sp10x) 0 var(--sp8x) 0;
		--footerColumnTitleMarginBottom: var(--sp2x);
		--footerMenuLinkMarginBottom: var(--sp2x);
		--payPalMarginTopBottom: var(--sp8x) 0 var(--sp2-5x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--footerPadding: var(--sp10x) 0 var(--sp8x) 0;
		--footerColumnTitleMarginBottom: var(--sp2x);
		--footerMenuLinkMarginBottom: var(--sp2x);
		--payPalMarginTopBottom: var(--sp8x) 0 var(--sp2-5x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--footerPadding: var(--sp8x) 0 var(--sp8x) 0;
		--footerColumnTitleMarginBottom: var(--sp2x);
		--footerMenuLinkMarginBottom: var(--sp2x);
		--payPalMarginTopBottom: var(--sp8x) 0 var(--sp1-5x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--footerPadding: var(--sp8x) 0 var(--sp5x) 0;
		--footerColumnTitleMarginBottom: var(--sp2x);
		--footerMenuLinkMarginBottom: var(--sp2x);
		--payPalMarginTopBottom: var(--sp8x) 0 var(--sp1-5x) 0;

		.col-contact-us,
		.col-follow-us {
			margin-top: var(--sp8x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--footerPadding: var(--sp5x) 0 var(--sp5x) 0;
		--footerColumnTitleMarginBottom: var(--sp2x);
		--footerMenuLinkMarginBottom: var(--sp2-5x);
		--payPalMarginTopBottom: var(--sp8x) 0 var(--sp1-5x) 0;

		.col-contact-us,
		.col-follow-us {
			margin-top: var(--sp5x);
		}

		.rights-wrap {
			flex-direction: column;

			.concept-info {
				margin-top: var(--sp1x);
			}
		}
	}
`;

export default FooterStyle;
