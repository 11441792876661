import { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

//* Helpers
import { config } from '@/helpers';

//* Components
import Icon from '../../Icon';

const SearchPopup = ({ isSearchDropdownActive, setIsSearchDropdownActive, translate, closeBurgerMenu }) => {
	//! Next Navigation
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	//! States
	const [searchValue, setSearchValue] = useState('');
	const search = searchParams.get('s');

	useEffect(() => {
		if (search) {
			setSearchValue(search);
		}
	}, [search]);

	useEffect(() => {
		setSearchValue('');
	}, [pathname, search]);

	useEffect(() => {
		if (isSearchDropdownActive) {
			closeBurgerMenu();
		}
	}, [isSearchDropdownActive]);

	//! Handle Search
	const handleSearch = useCallback((e) => {
		const str = e.target.value;

		setSearchValue(str);
	}, []);

	//! Check className
	const checkClass = useCallback((el, cl) => el.classList.contains(cl) || !!el.closest(`.${cl}`), []);

	//! Detect click outside
	const handleClickOutside = useCallback(
		(event) => {
			if (checkClass(event.target, `search-wrap`) && !isSearchDropdownActive) {
				setIsSearchDropdownActive(true);
			} else if (!checkClass(event.target, 'search-dropdown') && isSearchDropdownActive) {
				setIsSearchDropdownActive(false);
			}
		},
		[isSearchDropdownActive]
	);

	//! Add event listener
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	//! Handle key up
	const handleKeyUp = () => {
		router.push(`${config.routes.shop.path}/?s=${searchValue}`);
		setIsSearchDropdownActive(false);
	};

	return (
		<div className={`search-wrap ${isSearchDropdownActive ? 'show' : ''}`}>
			<Icon
				name={'search'}
				className={'h2 search-icon'}
			/>

			<div className='search-dropdown'>
				<Input
					value={searchValue}
					onChange={handleSearch}
					placeholder={translate('search')}
					onKeyUp={(e) => (e.key === 'Enter' ? handleKeyUp() : null)}
				/>
			</div>
		</div>
	);
};

export default SearchPopup;
